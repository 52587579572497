.why-choose-us {
    background-color: #f0f2f2; /* Light grey background for contrast */
    padding: 60px 0; /* Space above and below */
  }
  
  .why-choose-us h2 {
    font-size: 2rem;
    color: #262626;
    margin-bottom: 30px;
  }
  
  .why-icon {
    font-size: 2.5rem;
    color: #F2BB13; /* Brand's yellow color */
    margin-bottom: 10px;
  }
  
  .why-choose-us h5 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #262626;
  }
  
  .why-choose-us p {
    color: #6E7372; /* Slightly lighter grey for the description */
  }
  