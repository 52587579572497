.values-and-approach {
    background-color: #f0f2f2; /* Light background for contrast */
    color: #262626;
  }
  
  .value-card {
    border: none;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-aligns content inside the card */
  }
  
  .value-icon {
    font-size: 2.5rem;
    color: #F2BB13; /* Yellow accent color */
    margin-bottom: 15px;
  }
  
  
  .value-card h5 {
    color: #262626;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .value-card p {
    color: #6e7372;
  }
  
  .approach-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #6e7372;
    max-width: 800px;
    margin: 0 auto;
  }
  
  hr {
    border-top: 1px solid #ddd;
    margin: 20px 0;
  }
  