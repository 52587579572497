.portfolio-page {
    background-color: #f0f2f2;
    color: #262626;
  }
  
  .portfolio-card {
    border: none;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* Make sure the overlay is above other content */
  }
  
  .coming-soon {
    color: #ff0000; /* Red color for the "Coming Soon" text */
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly opaque background for better contrast */
    padding: 10px 20px;
    border-radius: 8px;
  }
  