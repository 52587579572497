.key-services {
    background-color: #f9f9f9; /* Light background to create contrast with the hero section */
    padding: 60px 0; /* Adds space above and below the section */
  }
  
  .key-services h2 {
    color: #262626; /* Dark color for better readability */
    font-size: 2rem;
    margin-bottom: 40px;
  }
  
  .text-center {
    text-align: center;
  }
  