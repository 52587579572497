.detailed-service-card {
    border: none;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted look */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth hover effect */
  }
  
  .detailed-service-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .detailed-service-card .card-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #262626; /* Dark text for titles */
    margin-bottom: 10px;
  }
  
  .detailed-service-card .card-text {
    font-size: 1rem;
    color: #6e7372; /* Slightly lighter grey for readability */
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .detailed-service-card .price {
    font-size: 1.1rem;
    font-weight: bold;
    color: #262626;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .detailed-service-card .features-list {
    padding-left: 20px; /* Indent the list for better readability */
    margin-top: 10px;
  }
  
  .detailed-service-card .features-list li {
    list-style-type: disc; /* Standard bullet points */
    margin-bottom: 5px;
  }
  
  .detailed-service-card .btn-warning {
    background-color: #F2BB13; /* Brand's yellow for consistency */
    border-color: #F2BB13;
    font-weight: 500;
  }
  
  .detailed-service-card .btn-warning:hover {
    background-color: #F2A413; /* Slightly darker shade for hover */
    border-color: #F2A413;
  }
  