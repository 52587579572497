.introduction {
    background-color: #f0f2f2; /* Light background to keep the focus on the content */
    color: #262626;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: 600;
    color: #262626;
    margin-bottom: 20px;
  }
  
  .intro-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #6e7372; /* Slightly lighter for readability */
  }
  
  .intro-image {
    width: 300px; /* Adjust the width as needed */
    height: 300px; /* Keep the height the same as the width for a circle */
    border-radius: 50%; /* Makes the image a perfect circle */
    object-fit: cover; /* Ensures the image scales properly within the circle */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    border: 4px solid #f0f2f2; /* Optional: adds a border around the image */
  }
  
  