.contact-section {
  background-color: #ffffff;
  padding: 60px 20px;
  text-align: center;
}

.contact-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #262626;
}

.contact-description {
  margin-bottom: 30px;
  font-size: 18px;
  color: #6e7372;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  color: #262626;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submit-btn {
  background-color: #f2bb13;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #f2a413;
}

.success-message {
  text-align: center;
  color: #f2bb13; /* Match your brand's accent color */
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #f2bb13; /* Yellow border for consistency */
  background-color: #fffbea; /* Light yellow background for visibility */
  border-radius: 8px;
  position: relative;
  animation: fadeIn 0.5s ease-in;
}

.success-message .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #f2bb13; /* Same yellow as the border */
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
