.hero-section {
    background-image: url('../assets/heroBackground.jpg'); /* Replace with the actual path to your background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1; /* Ensure the overlay is behind */
  }
  
  .hero-heading,
  .hero-btn {
    z-index: 2; /* Make sure the text and button are above the overlay */
    position: relative; /* Ensure they stay on top */
  }
  
  .hero-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #F0F2F2; /* Light color for better contrast */
  }
  
  .hero-btn {
    background-color: #F2BB13; /* Button color to match your palette */
    border-color: #F2BB13;
  }
  
  .hero-btn:hover {
    background-color: #F2A413; /* Darker shade for hover */
    border-color: #F2A413;
  }
