.call-to-action {
    background-color: #262626; /* Dark background to contrast with lighter sections */
    color: #F0F2F2; /* Light text for readability */
    padding: 60px 0; /* Space above and below */
  }
  
  .cta-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #F0F2F2;
  }
  
  .cta-subtitle {
    font-size: 1.1rem;
    color: #d1d3d4; /* Slightly lighter for contrast with the title */
    margin-bottom: 0;
  }
  
  .cta-button {
    background-color: #F2BB13;
    border-color: #F2BB13;
    padding: 10px 20px;
    font-weight: 500;
  }
  
  .cta-button:hover {
    background-color: #F2A413;
    border-color: #F2A413;
  }
  