/* Header.css */

/* Adjust the logo size */
.brand-logo .logo-img {
    width: 40px;
    height: auto;
}

.navbar-dark .navbar-nav .nav-link {
    color: #F0F2F2;
    /* Use one of your palette colors for the text */
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #F2D129;
    /* Highlight color on hover */
}

.btn-warning {
    background-color: #F2BB13;
    /* Button color */
    border-color: #F2BB13;
}

.btn-warning:hover {
    background-color: #F2A413;
    /* Darker shade for hover */
    border-color: #F2A413;
}

/* Style adjustments for mobile */
@media (max-width: 992px) {
    .navbar-brand {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar-collapse {
        background: #262626;
        /* Background color for collapsed menu */
        padding: 1rem;
    }
}