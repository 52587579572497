.footer {
    background-color: #1d1d1d; /* Dark background to match the website */
    padding: 20px 0;
    color: #ffffff; /* White text for readability */
    font-size: 0.9rem;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between; /* Aligns logo and copyright on opposite sides */
    align-items: center;
    padding: 0 40px; /* Adds padding to keep the content away from the edges */
  }
  
  .footer-logo-section {
    display: flex;
    align-items: center;
  }
  
  .footer-logo {
    height: 60px; /* Adjust the logo size */
    margin-right: 10px;
  }
  
  .footer-copyright-section {
    text-align: right;
  }
  
  .footer-link {
    color: #ffffff; /* Yellow accent color */
    text-decoration: none;
  }
  
  .footer-link:hover {
    color: #e6a600; /* Slightly different yellow on hover */
  }
  