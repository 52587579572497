.contact-page {
    background-color: #ffffff;
    padding: 60px 0;
  }
  
  .contact-info, .location {
    margin-top: 40px;
    color: #262626;
  }
  
  .contact-info h3, .location h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #262626;
  }
  
  .contact-info p, .location p {
    font-size: 18px;
    color: #6e7372;
  }
  
  .contact-info a {
    color: #f2bb13;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  