/* Social media section styling for all screen sizes */
.social-media-section {
    background-color: #1d1d1d;
    /* Dark background similar to "About Us" */
    padding: 60px 0;
    /* Thin section */
    text-align: center;
}

.social-media-title {
    font-size: 32px;
    color: #ffffff;
    /* Match yellow accent */
    margin-bottom: 30px;
    font-weight: bold;
}

.social-media-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap; /* Allows the icons to wrap on smaller screens */
}

.social-media-container a {
    color: #ffffff;
    font-size: 2.5rem;
    /* Larger icons */
    transition: color 0.3s ease, background-color 0.3s ease;
    width: 70px;
    /* Define a fixed size for the circle */
    height: 70px;
    border-radius: 50%;
    /* Make it circular */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff;
    /* White border to match the accents */
    text-align: center;
    padding: 10px;
}

.social-media-container a:hover {
    background-color: #f4b400; /* Change background color on hover */
    color: #1d1d1d; /* Dark background color on hover */
}

.social-media-container i {
    cursor: pointer;
}

/* Mobile styling for screens smaller than 768px */
@media (max-width: 768px) {
    .social-media-title {
        font-size: 24px; /* Smaller title for mobile */
    }

    .social-media-container {
        gap: 20px; /* Reduce space between icons */
        flex-wrap: wrap; /* Stack the icons in rows if necessary */
    }

    .social-media-container a {
        font-size: 2rem; /* Smaller icon size for mobile */
        width: 50px; /* Smaller circle for mobile */
        height: 50px;
    }
}
