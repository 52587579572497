.service-card {
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 100%; /* Ensure the card stretches to fill the container */
    display: flex;
    flex-direction: column; /* Allows the content to stack vertically */
    justify-content: space-between; /* Distribute space evenly */
  }
  
  .service-card .card-body {
    flex: 1; /* Ensures that the card body takes up remaining space */
  }
  
  .service-icon {
    font-size: 2.5rem;
    color: #F2BB13;
    margin-bottom: 15px;
  }
  
  .service-card h2,
  .service-card p {
    color: #262626;
  }
  
  .service-card .btn-primary {
    background-color: #F2BB13;
    border-color: #F2BB13;
  }
  
  .service-card .btn-primary:hover {
    background-color: #F2A413;
    border-color: #F2A413;
  }
  