.service-category {
    background-color: #f0f2f2; /* Light background for a clean look */
    padding: 40px 0;
  }
  
  .category-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #262626;
  }
  