.professional-background {
    background-color: #f0f2f2; /* Light background for contrast */
    color: #262626;
  }
  
  .skills-title,
  .certifications-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #262626;
    margin-bottom: 20px;
  }
  
  .skills-list {
    list-style-type: none;
    padding: 0;
    line-height: 1.8;
    color: #6e7372;
  }
  
  .skills-list li::before {
    content: "✓"; /* Adds a checkmark before each skill */
    margin-right: 8px;
    color: #F2BB13; /* Matches the brand's yellow color */
  }
  
  .certification-card {
    border: none;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .certification-card .card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #262626;
  }
  
  .certification-card .card-text {
    color: #6e7372;
    font-size: 0.9rem;
  }
  